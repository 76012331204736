import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 225.000000 225.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M1606 1875 c-20 -14 -39 -25 -41 -25 -2 0 -63 -36 -135 -80 -72 -44
-133 -80 -135 -80 -5 0 -136 -79 -144 -86 -2 -2 8 -15 22 -29 47 -47 45 -116
-8 -266 -13 -38 -22 -69 -20 -69 2 0 23 28 48 63 24 34 75 95 113 135 77 80
110 91 145 50 35 -43 25 -93 -47 -238 -36 -72 -64 -133 -61 -136 2 -2 24 26
47 63 52 81 160 197 199 213 37 15 76 -1 96 -41 24 -45 13 -103 -39 -219 -64
-141 -117 -278 -129 -337 -17 -83 5 -95 126 -67 39 9 35 -16 -8 -55 -27 -24
-45 -31 -76 -31 -70 0 -129 80 -129 175 0 73 87 326 147 428 28 47 37 87 20
87 -18 0 -92 -79 -137 -145 -18 -27 -37 -52 -41 -55 -10 -7 -133 -209 -142
-232 -9 -26 -44 -22 -63 7 -35 53 4 190 102 360 55 97 89 173 80 182 -8 8
-104 -89 -153 -157 -91 -127 -202 -310 -230 -384 -33 -84 -51 -103 -82 -86
-51 27 -38 83 85 380 104 251 124 314 107 331 -26 26 -126 -103 -142 -185 -8
-36 -23 -43 -46 -20 -22 23 -16 105 10 134 10 11 13 20 8 20 -12 0 -479 -275
-505 -298 -11 -9 -18 -29 -18 -48 0 -38 23 -59 135 -119 39 -21 109 -62 157
-92 48 -29 90 -53 92 -53 2 0 50 -27 106 -60 56 -33 103 -60 106 -60 2 0 34
-19 71 -42 38 -23 97 -58 133 -78 36 -20 145 -83 243 -139 185 -107 204 -113
241 -75 14 14 16 94 16 725 l0 710 -25 24 c-31 31 -53 31 -99 0z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
